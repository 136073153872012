import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import {getOperationName} from "apollo-utilities";

import Modal from "../../bootstrap/modal";
import {removeUserDeviceMutation} from "../../logic/device";
import { getCurrentUserQuery } from "../../logic/user";

export default function ChangeDetails(props) {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });

  const { onClose, device } = props;
  const formRef = useRef(null);
  const [removeDevice] = useMutation(removeUserDeviceMutation);

  async function handleSubmit() {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      await removeDevice({
        variables: {
          deviceId: device.id,
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      })

      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({ ...prevState, processing: false, error: err }));
    }
  }

  return (
    <Modal
      title={"Remove Device"}
      show
      onClose={async() => onClose()}
      footer={        
      <Button
        className="submit-request-button"
        type="submit"
        disabled={state.processing}
        onClick={() => formRef.current.submit()}
      >
        {"Delete"}
      </Button>
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit}>
        {state.error && <Row>
          <Col>
            <div className="alert alert-danger">
              {state.error}
            </div>
          </Col>
        </Row>}
        <Row>
          <Col>
            <div className="text-center">
              {`Delete Device `}
              <span className="font-weight-bold">
                {`${device.hostname || device.macAddress}`}
              </span>
            </div>
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
