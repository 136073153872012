import { gql } from "@apollo/client";

export const addUserDeviceMutation = gql`
  mutation addToUser ($hostname: String!, $macAddress: String!, $userId: ID!) {
    classMethods {
      Device {
        addToUser (hostname: $hostname, macAddress: $macAddress, userId: $userId) {
          id
        }
      }
    }
  }
`;

export const updateDeviceMutation = gql`
  mutation updateUserDevice($id: ID!, $hostname: String) {
    models {
      Device (update: {
        where: {id: {eq: $id}}
        input: {hostname: $hostname}
      }) {
        id
      }
    }
  }
`;

export const removeUserDeviceMutation = gql`
  mutation removeFromUser($deviceId: ID!) {
    models {
      UserDevice (delete: {
        deviceId: {eq: $deviceId}
      }) {
        deviceId
      }
    }
  }
`;