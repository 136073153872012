import React, { useRef } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useMutation } from "@apollo/client";
import { parsePhoneNumber, isValidPhoneNumber } from "react-phone-number-input";
import { getOperationName } from "apollo-utilities";

import TextField from "../bootstrap/input";
import Modal from "../bootstrap/modal";
import PhoneInput from "../bootstrap/phone-input";
import { updateUserMutation, getCurrentUserQuery } from "../logic/user";
import { validationChecks, validationErrors } from "../logic/validations";

export default function ChangeDetails(props) {
  const [state, setState] = useAsyncSetState({
    processing: false,
    error: "",
  });

  const { onClose, user } = props;
  const formRef = useRef(null);
  const [updateUser] = useMutation(updateUserMutation);

  async function handleSubmit(model) {
    await setState((prevState) => ({ ...prevState, processing: true }));

    try {
      await updateUser({
        variables: {
          userId: user.id,
          input: {
            email: model.email,
            mobileNumber: model.mobile,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });

      await setState((prevState) => ({ ...prevState, processing: false }));
      return onClose();
    } catch (err) {
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error: err,
      }));
    }
  }

  return (
    <Modal
      title={"Change Details"}
      show
      onClose={async () => onClose()}
      footer={
        <Container>
          <Row>
            <Col xs="auto ml-auto">
              <Button
                className="vw-button black-text"
                type="button"
                disabled={state.processing}
                onClick={(e) => {
                  e.preventDefault();
                  return onClose();
                }}
              >
                {"Cancel"}
              </Button>
            </Col>
            <Col xs="auto">
              <Button
                variant="black"
                className="vw-button black"
                type="submit"
                disabled={state.processing}
                onClick={() => formRef.current.submit()}
              >
                {"Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      }
    >
      <Formsy ref={formRef} onValidSubmit={handleSubmit}>
        {state.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{state.error}</div>
            </Col>
          </Row>
        )}
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              label={"First Name"}
              name="firstName"
              defaultValue={user.firstName}
              placeholder={"First Name"}
              disabled
              testid="firstName"
              required
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              defaultValue={user.lastName}
              label={"Last Name"}
              name="lastName"
              placeholder={"Last Name"}
              testid="lastName"
              disabled
              required
            />
          </Col>
        </Row>
        <Row className="field-row mb-2">
          <Col>
            <TextField
              inline
              label={"Email"}
              name="email"
              placeholder={"Email"}
              defaultValue={user.email}
              testid="email"
              required
              validations="isEmail"
              validationErrors={{
                isEmail: "Enter a valid email",
              }}
              disabled={state.processing}
            />
          </Col>
        </Row>
        <Row className="field-row mb-2 align-items-center inline no-gutters">
          <Col>
            <TextField
              inline
              name="mobile"
              type="number"
              defaultValue={user.mobileNumber}
              label="Mobile Number"
              placeholder="Mobile Number"
              required
            />
          </Col>
        </Row>
      </Formsy>
    </Modal>
  );
}
