import React, { useRef, Fragment } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import Formsy from "formsy-react";
import { useAsyncSetState } from "use-async-setstate";
import { useQuery, useMutation } from "@apollo/client";
import { getOperationName } from "apollo-utilities";

import TextField from "../bootstrap/input";
import Modal from "../bootstrap/modal";
import { getProductsQuery, getProductsResult } from "../logic/products";
import {
  purchaseMutation,
  getCurrentUserQuery,
  getPurchaseResult,
} from "../logic/user";
import Loader from "../bootstrap/loader";
import Main from "../main";
import Layout from "../section/layout";
import Seo from "../section/seo";
import { console } from "window-or-global";

import {
  getPageInfoQuery,
  getEwayClientKey,
  getBuilding,
} from "../logic/general";

export default function Index(props) {
  return (
    <Main>
      <Layout>
        <Seo title="Dayman Apartments - Change Plan" />
        <DataLayer {...props} />
      </Layout>
    </Main>
  );
}

function DataLayer(props) {
  const pageInfoQuery = useQuery(getPageInfoQuery);

  if (pageInfoQuery.loading) {
    return <Fragment />;
  }

  const ewayKey = getEwayClientKey(pageInfoQuery);

  return <ChangePlan ewayKey={ewayKey} {...props} />;
}

function ChangePlan(props) {
  const [state, setState] = useAsyncSetState({
    success: false,
    confirm: false,
    processing: false,
    error: null,
    data: null,
    type: null,
    buildingCode: null,
  });

  const [purchase] = useMutation(purchaseMutation);

  const [voucherCode, setVoucherCode] = useAsyncSetState(null);
  const [voucherField, setVoucherField] = useAsyncSetState(null);
  const [selectedProduct, setProduct] = useAsyncSetState(null);

  const { onClose, user, ewayKey } = props;
  const formRef = useRef(null);

  async function handleSubmit(data) {
    return setState((prevState) => ({
      ...prevState,
      data,
      confirm: true,
      error: null,
    }));
  }

  async function handlePurchase() {
    const {
      cardNumber,
      cardName,
      cardCCV,
      expiryMonth,
      expiryYear,
    } = state.data;
    console.log({
      cardNumber,
      cardName,
      cardCCV,
      expiryMonth,
      expiryYear,
    })
    try {
      await setState((prevState) => ({
        ...prevState,
        confirm: false,
        processing: true,
      }));

      let creditCard = null,
        variables = {};
      if (selectedProduct.value > 0) {
        creditCard = {
          cardName,
          cardNumber: window.eCrypt.encryptValue(cardNumber, ewayKey),
          cardCCV: window.eCrypt.encryptValue(cardCCV, ewayKey),
          cardExpiry: `${expiryMonth}${expiryYear}`,
        };
      }

      console.log({creditCard})

      variables = {
        userId: user.id,
        products: [selectedProduct.id],
        schedule: state.type === "schedule" ? true : false,
        ccard: creditCard,
      };

      const response = await purchase({
        variables,
        awaitRefetchQueries: true,
        refetchQueries: [getOperationName(getCurrentUserQuery)],
      });
      console.log({ response });
      const result = getPurchaseResult(response);
      if ((result || {}).id) {
        return setState((prevState) => ({
          ...prevState,
          success: true,
          processing: false,
          confirm: false,
        }));
      }

      return setState((prevState) => ({
        ...prevState,
        processing: false,
        confirm: false,
        success: false,
        error: response?.error || "An error has occurred.",
      }));
    } catch (err) {
      console.error(err);
      return setState((prevState) => ({
        ...prevState,
        processing: false,
        error:
          err.message.replace(/(GraphQL error:)/gi, "") ||
          "An error has occurred.",
      }));
    }
  }

  return (
    <Modal
      title={"Change Plan"}
      show
      onClose={onClose}
      footer={(() => {
        if (!state.processing && !state.success && !state.confirm) {
          return (
            <Row>
              <Col xs="auto" className="ml-auto">
                <Button
                  variant="darkblue"
                  type="submit"
                  disabled={state.processing || selectedProduct === null}
                  onClick={async () => {
                    await setState((prevState) => ({
                      ...prevState,
                      type: "schedule",
                    }));
                    return formRef.current.submit();
                  }}
                >
                  {"Schedule"}
                  <i
                    style={{ marginLeft: 10 }}
                    className="far fa-angle-right fa-lg"
                  />
                </Button>
              </Col>
              <Col xs="auto">
                <Button
                  variant="darkblue"
                  type="submit"
                  disabled={state.processing || selectedProduct === null}
                  onClick={async () => {
                    await setState((prevState) => ({
                      ...prevState,
                      type: "change",
                    }));
                    return formRef.current.submit();
                  }}
                >
                  {"Change"}
                  <i
                    style={{ marginLeft: 10 }}
                    className="far fa-angle-right fa-lg"
                  />
                </Button>
              </Col>
            </Row>
          );
        }
      })()}
    >
      <Container>
        {state.error && (
          <Row>
            <Col>
              <div className="alert alert-danger">{state.error}</div>
            </Col>
          </Row>
        )}
        {(() => {
          if (state.processing) {
            return (<div>
              <Loader />
              {"Processing"}
            </div>);
          }

          if (state.success) {
            return (
              <div>
                <span>{"Your changes have been saved. Thank you"}</span>
                <Row className="vw-portal-button-popup-row justify-content-end">
                  <Col xs="auto">
                    <Button variant="darkblue" onClick={() => onClose()}>
                      {"Close"}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }

          if (state.confirm) {
            return (
              <div>
                <span>{`You will be charged $${selectedProduct.value} for ${selectedProduct.name}`}</span>
                <Row className="vw-portal-button-popup-row justify-content-end">
                  <Col xs="auto">
                    <Button
                      type="button"
                      variant="darkblue"
                      disabled={state.processing}
                      onClick={async () => handlePurchase()}
                    >
                      <i className="far fa-check"></i>
                      &nbsp;{"Confirm"}
                    </Button>
                  </Col>
                </Row>
              </div>
            );
          }

          return (
            <Formsy ref={formRef} onValidSubmit={handleSubmit}>
              <Row className="field-row mb-2">
                <Col xs={10}>
                  <TextField
                    inline
                    onChange={(e) => setVoucherField(e.target.value)}
                    label={"Voucher"}
                    name="voucherCode"
                    placeholder={"Voucher Code"}
                    disabled={state.processing || voucherCode !== null}
                    value={voucherField}
                  />
                </Col>
                <Col xs={2}>
                  <Button
                    className="vw-button"
                    variant={voucherCode ? "danger" : "darkblue"}
                    type="button"
                    onClick={async () => {
                      if (voucherCode) {
                        await setVoucherField(null);
                        await setVoucherCode(null);
                      } else {
                        await setVoucherCode(voucherField.trim());
                      }
                    }}
                  >
                    {voucherCode ? "Clear" : "Apply"}
                  </Button>
                </Col>
              </Row>
              <Row className="field-row mb-2">
                <Col key={voucherCode || 0}>
                  <ProductList
                    selectedProduct={selectedProduct}
                    voucherCode={voucherCode}
                    setProduct={setProduct}
                  />
                </Col>
              </Row>
              {(selectedProduct || {}).value > 0 && (
                <Fragment>
                  <Row className="field-row mb-2">
                    <Col>
                      <TextField
                        inline
                        label={"Card Number"}
                        name="cardNumber"
                        placeholder={"Card Number"}
                        validations={{
                          isNumeric: true,
                          maxLength: 19,
                          minLength: 16,
                        }}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="field-row mb-2">
                    <Col>
                      <TextField
                        inline
                        label={"Card Name"}
                        placeholder={"Card Name"}
                        name="cardName"
                        disabled={state.processing}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="field-row mb-2">
                    <Col>
                      <TextField
                        inline
                        label={"CCV"}
                        name="cardCCV"
                        placeholder={"CCV"}
                        validations={{
                          isNumeric: true,
                          isLength: 3,
                        }}
                        disabled={state.processing}
                        required
                      />
                    </Col>
                  </Row>
                  <Row className="field-row no-gutters mb-2">
                    <Col style={{ paddingTop: 5 }} xs={3}>
                      <label>{"Month (MM)"}</label>
                    </Col>
                    <Col style={{ marginLeft: 8 }} xs={3}>
                      <TextField
                        inline
                        name="expiryMonth"
                        placeholder={"Month (MM)"}
                        validations={{
                          isNumeric: true,
                          minLength: 2,
                          maxLength: 2,
                        }}
                        disabled={state.processing}
                        required
                      />
                    </Col>
                    <Col
                      style={{ marginLeft: 8, marginRight: 8 }}
                      className="align-self-center"
                      xs="auto"
                    >
                      <span style={{ fontSize: 30 }}>{"/"}</span>
                    </Col>
                    <Col xs={3}>
                      <TextField
                        inline
                        name="expiryYear"
                        placeholder={"Year (YY)"}
                        validations={{
                          isNumeric: true,
                          minLength: 2,
                          maxLength: 2,
                        }}
                        disabled={state.processing}
                        required
                      />
                    </Col>
                  </Row>
                </Fragment>
              )}
            </Formsy>
          );
        })()}
      </Container>
    </Modal>
  );
}

function ProductList({
  voucherCode,
  buildingCode,
  selectedProduct,
  setProduct,
}) {
  const getProducts = useQuery(getProductsQuery, {
    variables: {
      voucherCode,
      buildingCode,
    },
  });

  if (getProducts?.loading) {
    return <Loader />;
  }

  const products = getProductsResult(getProducts);
  console.log("PRODUCTS >>> ", { products });
  return (
    <Container key={voucherCode || 0}>
      {products.map((product, index) => {
        return (
          <Row
            key={index}
            className="product-row"
            noGutters
            onClick={async () => setProduct && (await setProduct(product))}
          >
            <Col xs={1} className="mr-2">
              <i
                className={
                  selectedProduct?.id === product?.id
                    ? `far fa-dot-circle`
                    : `far fa-circle`
                }
              />
            </Col>
            <Col xs={2} className="mr-2 font-primary-bold">
              {`$${product.value.toFixed(2)}`}
            </Col>
            <Col>{product.description}</Col>
          </Row>
        );
      })}
    </Container>
  );
}
